import Script from "next/script";

const BUNDLE_LINK = process.env.NEXT_PUBLIC_BUNDLE_LINK;

export type AtlasSnippetProps = {
  appId: string;
  chatting?: boolean;
  recording?: boolean;
  customerPortal?: boolean;
};

export default function AtlasSnippet({
  appId,
  chatting,
  recording,
  customerPortal,
}: AtlasSnippetProps) {
  if (!chatting && !recording && !customerPortal) return null;

  const startOptions = {
    recording: {
      enabled: !!recording,
    },
    chat: {
      enabled: !!chatting,
      // widget SDK needs to run for Customer portal. Hiding the bubble if chat is turned off
      openIncoming: !!chatting,
      hideBubble: !chatting,
    },
  };

  return (
    <Script id="script-atlas-snippet">{`
        (()=>{"use strict";const t={appId:"${appId}",v:2,autorun:${JSON.stringify(
      startOptions
    )},q:[],start:function(o){this.autorun=o||!0},identify:function(t){this.q.push(["identify",t])}};window.Atlas=t;const e=document.createElement("script");e.async=!0,e.src="${BUNDLE_LINK}";const s=document.getElementsByTagName("script")[0];s.parentNode?.insertBefore(e,s)})();
    `}</Script>
  );
}
